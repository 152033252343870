import React from 'react'

const Services = () => {
  return (
    <div className="middle">
  <div className="our-services-men">
    <div className="container">
      <div className="heading-men">
        <h2>Our Services</h2>
        <p>Thousands of satisfied customers and top-rated car maintenance services.</p>
      </div>
      <div className="row mt-4">
        <div className="col-lg-3 col-md-6" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration={2000}>
          <div className="our-services-one">
            <img className="img-fluid" src="images/icon1.png" alt="Icon" />
            <h2>Regular Washes</h2>
            <p>Waterless car wash with a regular subscription cleans and restores your car’s shine, leaving it glossy and spotless without the need for water.</p>
          </div>
        </div>
        <div className="col-lg-3 col-md-6" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration={2000}>
          <div className="our-services-one">
            <img className="img-fluid" src="images/icon2.png" alt="Icon" />
            <h2>Exterior Detailing</h2>
            <p>Car wash, waxing, polishing, and paint protection to restore your car’s shine and safeguard against damging elements like UV rays, bird droppings, pigmentations, clear coat scratches</p>
          </div>
        </div>
        <div className="col-lg-3 col-md-6" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration={2000}>
          <div className="our-services-one">
            <img className="img-fluid" src="images/icon3.png" alt="Icon" />
            <h2>Interior Detailing</h2>
            <p>Deep cleaning of carpets, seats, along with dashboard and console cleaning, leaving your car’s interior fresh and spotless.</p>
          </div>
        </div>
        <div className="col-lg-3 col-md-6" data-aos="flip-left" data-aos-easing="ease-out-cubic" data-aos-duration={2000}>
          <div className="our-services-one">
            <img className="img-fluid" src="images/icon4.png" alt="Icon" />
            <h2>Specialty Services</h2>
            <p>Ceramic coating, paint correction, headlight restoration, and engine bay cleaning, all designed to enhance your vehicle’s appearance and extend its longevity</p>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="At-fingertips-men">
    <div className="container">
      <div className="row">
        <div className="col-lg-12 col-md-12" data-aos="zoom-in" data-aos-duration={2000}>
          <div className="At-fingertips-left">
            <div className="heading-men p-0">
              <img className="app-icon-photo" src="images/app-icon.png" alt="App Icon" />
              <h2>At your doorstep. By your fingertips</h2>
              <p className="pt-2">The most convenient, professional &amp; eco-friendly car wash solution. <br /> At your doorstep. On tap of a button.</p>
              <div className="all-btn">
              <div className="row mt-4 justify-content-center">
                      <div className="col-lg-2 play" >
                        <div className="btn1 btn2">
                          <a href="https://play.google.com/store/apps/details?id=com.washwoosh.userapp&pcampaignid=web_share">
                            <img
                              className="img-fluid"
                              src="images/d-app1.png"
                            />
                          </a>
                        </div>

                        <div className="btn1 btn2">
                        <a href="https://apps.apple.com/in/app/washwoosh-car-care-app/id6738276020">
                          <img className="img-fluid" src="images/d-app2.png" />
                        </a>
                      </div>
                      </div>
                    </div>
                  </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  )
}

export default Services
