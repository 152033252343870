import React from 'react';

const HowItWorks = () => {
  return (
    <div className="middle">
      <div className="how-it-works">
        <div className="container">
          <div className="heading-men">
            <h2>How it works?</h2>
            <p>WashWoosh aims at providing quality at your convenience.</p>
          </div>
          <div className="row pt-4" data-aos="zoom-in" data-aos-duration={3000}>
            <div className="col-lg-4 col-md-4">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="how-works-one how-works-active">
                    <img
                      className="img-fluid"
                      src="images/Download-2.svg"
                      alt="Icon"
                    />
                    <h2>Download WashWoosh app available on iOS and Android</h2>
                  </div>
                </div>
                <div
                  className="col-lg-12 col-md-12 mobile-marg"
                  style={{ marginTop: "120px" }}
                >
                  <div className="how-works-one">
                    <img
                      className="img-fluid"
                      src="images/add car_3.svg"
                      alt="Icon"
                    />
                    <h2>
                      Add your vehicle to <br /> the profile
                    </h2>
                  </div>
                </div>
                <div
                  className="col-lg-12 col-md-12 mobile-marg"
                  style={{ marginTop: "110px" }}
                >
                  <div className="how-works-one">
                    <img
                      className="img-fluid"
                      src="images/Payment_1_2.svg"
                      alt="Icon"
                    />
                    <h2>Confirm your Subscripiton and just sit back and relax</h2>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-4 modile-box1"
              style={{ position: "relative" }}
            >
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="how-works-center">
                    <span>1</span>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="how-works-center-two">
                    <span>2</span>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="how-works-center-three">
                    <span>3</span>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="how-works-center-for">
                    <span>4</span>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="how-works-center-five">
                    <span>5</span>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="how-works-center-six">
                    <span>6</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="row">
                <div
                  className="col-lg-12 col-md-12 mobile-marg"
                  style={{ marginTop: "150px"}}
                >
                  <div className="how-works-one how-works-rignt">
                    <img
                      className="img-fluid"
                      src="images/Mobile login3.svg"
                      alt="Icon"
                    />
                    <h2>
                      Sign up and add <br /> your details
                    </h2>
                  </div>
                </div>
                <div
                  className="col-lg-12 col-md-12 mobile-marg"
                  style={{ marginTop: "100px" }}
                >
                  <div className="how-works-one how-works-rignt confirm-order">
                    <img
                      className="img-fluid"
                      src="images/Select-bro.svg"
                      alt="Icon"
                    />
                    <h2>Select required service's from the Service list </h2>
                  </div>
                </div>
                <div
                  className="col-lg-12 col-md-12 mobile-marg"
                  style={{ marginTop: "100px" }}
                >
                  <div className="how-works-one how-works-rignt ">
                    <img
                      className="img-fluid"
                      src="images/Select-pana.svg"
                      alt="Icon"
                    />
                    <h2>
                      Select your preferred payment method and our bros will be on
                      their way!
                    </h2>
                  </div>
                </div>
              </div>
               </div>
          </div>
        </div>
      </div>
      <div className="At-fingertips-men">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-12 col-md-12"
              data-aos="zoom-in"
              data-aos-duration={2000}
            >
              <div className="At-fingertips-left">
                <div className="heading-men p-0">
                  <img
                    className="app-icon-photo"
                    src="images/app-icon.png"
                    alt="App Icon"
                  />
                  <h2>At your doorstep. By your fingertips</h2>
                  <p className="pt-2">
                    The most convenient, professional &amp; eco-friendly car
                    wash solution. <br /> At your doorstep. On tap of a button.
                  </p>
                  <div className="all-btn">
                  <div className="row mt-4 justify-content-center">
                      <div className="col-lg-2 play" >
                        <div className="btn1 btn2">
                          <a href="https://play.google.com/store/apps/details?id=com.washwoosh.userapp&pcampaignid=web_share">
                            <img
                              className="img-fluid"
                              src="images/d-app1.png"
                            />
                          </a>
                        </div>

                        <div className="btn1 btn2">
                        <a href="https://apps.apple.com/in/app/washwoosh-car-care-app/id6738276020">
                          <img className="img-fluid" src="images/d-app2.png" />
                        </a>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;
