import React from "react";
import { Route, Routes } from "react-router-dom";
import Home from "../Home/Home";
import PrivacyPolicy from "../PrivacyPolicy/PrivacyPolicy";
import RefundPolicy from "../RefundPolicy/RefundPolicy";
import HowItWorks from "../HowItWorks/HowItWorks";
import Contact from "../Contact/Contact";
import Services from "../Services/Services";
import Terms from "../Terms&Cond/Terms&Cond";
import Aboutus from "../Aboutus/Aboutus";
import Blog from "../Blog/Blog";
import BlogDetails from "../BlogDetails/BlogDetails";
import BlogSearchCategory from "../BlogSearchCategory/BlogSearchCategory";
import BlogSearch from "../BlogSearch/BlogSearch";

const AppRouter = () => {
  return (
    <div>
      <Routes>
      <Route exact path="/" element={<Home/>} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy/>} />
        <Route exact path="/aboutus" element={<Aboutus/>} />
        <Route exact path="/refund-policy" element={<RefundPolicy/>} />
        <Route exact path="/how-it-works" element={<HowItWorks/>} />
        <Route exact path="/contact" element={<Contact/>} />
        <Route exact path="/services" element={<Services/>} />
        <Route exact path="/terms" element={<Terms/>} />
        {/* <Route exact path="/blogs" element={<Blog/>}/>
        <Route exact path="/blogs" element={<BlogDetails/>}/>
        <Route path="/blog/:value" element={<BlogSearchCategory />} />
        <Route path="/blog/search/:value" element={<BlogSearch />} /> */}








      </Routes>
    </div>
  );
};

export default AppRouter;
