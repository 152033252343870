import React from "react";
import { Link } from "react-router-dom";

const Home = () => {
  const handleLinkClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  return (
    <div className="middle">
      <div className="header-banner">
        <video
          className="bg-video"
          width="100%"
          height="550px"
          muted
          autoPlay
          playsinline="true"
          loop
          preload="auto"
          onloadstart="this.volume=0.2"
          style={{ objectFit: "cover" }}
        >
          <source src="images/movie.mp4" type="video/mp4" />
          {/* <source src="images/movie.mp4" type="video/ogg" /> */}
        </video>
        <div className="container">
          <div className="row banner-men">
            <div className="col-lg-6 col-md-6">
              <div className="banner-text">
                <h2>
                  <span>Clean </span> Convenience
                </h2>
                <p>
                  Get expert car washing services delivered right to your house
                  with WashWoosh, the ultimate in convenience. We understand
                  that your car is more than just a vehicle; it's a reflection
                  of your personality and style . That's why we give spotless
                  cleaning services while preserving the environment by using
                  eco-friendly and branded products and modern waterless
                  cleaning techniques. Take advantage of a comprehensive , easy
                  car wash that keeps your car looking its best wherever you are
                  and at any time !
                </p>
                <div className="all-btn">
                  <div className="row">
                    <div className="col-lg-3 playapp">
                      <div className="btn1">
                        <a href="https://play.google.com/store/apps/details?id=com.washwoosh.userapp&pcampaignid=web_share">
                          <img className="img-fluid" src="images/d-app1.png" />
                        </a>
                      </div>
                      <div className="btn1">
                        <a href="https://apps.apple.com/in/app/washwoosh-car-care-app/id6738276020">
                          <img className="img-fluid" src="images/d-app2.png" />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-5 col-md-6">
              <div
                className="banner-text banner-text-one text-end"
                //   data-aos="fade-up"
                data-aos="flip-left"
                data-aos-easing="ease-out-cubic"
                data-aos-duration={2000}
                //   data-aos="fade-up"
                // data-aos-offset="200"
                // data-aos-delay="50"
                // data-aos-duration="1000"
                // data-aos-easing="ease-in-out"
                // data-aos-mirror="false"
                // data-aos-once="true"
              >
                <img
                  className="img-fluid"
                  src="images/banner-photo.png"
                  alt="Banner"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="about-men">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <div
                className="about-text"
                data-aos="fade-down"
                data-aos-duration={2000}
              >
                <h2>About Us</h2>
                <p className="welcome-text">
                  {/* <b>Welcome To WashWoosh</b> */}
                </p>
                <p>
                  Welcome to WashWoosh, where we believe that your car deserves
                  the best care without any inconvenience ! As a premier mobile
                  car washing company, we bring professional car cleaning
                  services directly to your doorstep, ensuring your vehicle
                  shines like new while you go about your day. At WashWoosh, we
                  are committed to using only the highest quality, eco-friendly
                  products. Our carefully selected branded cleaning solutions
                  are not only effective but also safe for the environment,
                  providing you peace of mind as we pamper your vehicle. Our
                  skilled team of professionals is trained to deliver
                  exceptional results by transforming your car into a
                  masterpiece.
                </p>
                {/* <div class="book-mafia mt-4">
                  <Link to={"/aboutus"}>
                    <a className="btn head-btn">
                      <span>Read More ⇾</span>
                    </a>
                  </Link>
                </div> */}
              </div>
            </div>
            <div className="col-lg-6 col-md-6">
              <div
                className="banner-text banner-text-car text-end"
                data-aos="zoom-in-up"
                data-aos-duration={2000}
              >
                <img
                  className="img-fluid"
                  src="images/car-wash1.png"
                  alt="Photo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="how-it-works">
        <div className="container">
          <div className="heading-men">
            <h2>How it works?</h2>
            <p>WashWoosh aims at providing quality at your convenience.</p>
          </div>
          <div className="row pt-4" data-aos="zoom-in" data-aos-duration={3000}>
            <div className="col-lg-4 col-md-4">
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="how-works-one how-works-active">
                    <img
                      className="img-fluid"
                      src="images/Download-2.svg"
                      alt="Icon"
                    />
                    <h2>Download WashWoosh app available on iOS and Android</h2>
                  </div>
                </div>
                <div
                  className="col-lg-12 col-md-12 mobile-marg"
                  style={{ marginTop: "120px" }}
                >
                  <div className="how-works-one">
                    <img
                      className="img-fluid"
                      src="images/add car_3.svg"
                      alt="Icon"
                    />
                    <h2>
                      Add your vehicle to <br /> the profile
                    </h2>
                  </div>
                </div>
                <div
                  className="col-lg-12 col-md-12 mobile-marg"
                  style={{ marginTop: "110px" }}
                >
                  <div className="how-works-one">
                    <img
                      className="img-fluid"
                      src="images/Payment_1_2.svg"
                      alt="Icon"
                    />
                    <h2>
                      Confirm your Subscripiton and just sit back and relax
                    </h2>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 col-md-4 modile-box1"
              style={{ position: "relative" }}
            >
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="how-works-center">
                    <span>1</span>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="how-works-center-two">
                    <span>2</span>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="how-works-center-three">
                    <span>3</span>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="how-works-center-for">
                    <span>4</span>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="how-works-center-five">
                    <span>5</span>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="how-works-center-six">
                    <span>6</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4">
              <div className="row">
                <div
                  className="col-lg-12 col-md-12 mobile-marg"
                  style={{ marginTop: "150px" }}
                >
                  <div className="how-works-one how-works-rignt">
                    <img
                      className="img-fluid"
                      src="images/Mobile login3.svg"
                      alt="Icon"
                    />
                    <h2>
                      Sign up and add <br /> your details
                    </h2>
                  </div>
                </div>
                <div
                  className="col-lg-12 col-md-12 mobile-marg"
                  style={{ marginTop: "100px" }}
                >
                  <div className="how-works-one how-works-rignt confirm-order">
                    <img
                      className="img-fluid"
                      src="images/Select-bro.svg"
                      alt="Icon"
                    />
                    <h2>Select required service's from the Service list </h2>
                  </div>
                </div>
                <div
                  className="col-lg-12 col-md-12 mobile-marg"
                  style={{ marginTop: "100px" }}
                >
                  <div className="how-works-one how-works-rignt ">
                    <img
                      className="img-fluid"
                      src="images/Select-pana.svg"
                      alt="Icon"
                    />
                    <h2>
                      Select your preferred payment method and our bros will be
                      on their way !
                    </h2>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="middle-banner">
        <div className="container-fluid p-0">
          <div
            className="middle-banner-text"
            data-aos="zoom-in"
            data-aos-easing="ease-out-cubic"
            data-aos-duration={2000}
          >
            <img
              className="img-fluid"
              src="images/middle-banner.png"
              alt="Banner"
            />
          </div>
        </div>
      </div>
      <div className="our-services-men">
        <div className="container">
          <div className="heading-men">
            <h2>Our Services</h2>
            <p>
              Thousands of satisfied customers and top-rated car maintenance
              services.
            </p>
          </div>
          <div className="row mt-4">
            <div
              className="col-lg-3 col-md-6"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration={2000}
            >
              <div className="our-services-one">
                <img className="img-fluid" src="images/icon1.png" alt="Icon" />
                <h2>Regular Washes</h2>
                <p>
                  Waterless car wash with a regular subscription cleans and
                  restores your car’s shine, leaving it glossy and spotless
                  without the need for water.
                </p>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration={2000}
            >
              <div className="our-services-one">
                <img className="img-fluid" src="images/icon2.png" alt="Icon" />
                <h2>Exterior Detailing</h2>
                <p>
                  Car wash, waxing, polishing, and paint protection to restore
                  your car’s shine and safeguard against damging elements like
                  UV rays, bird droppings, pigmentations, clear coat scratches
                </p>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration={2000}
            >
              <div className="our-services-one">
                <img className="img-fluid" src="images/icon3.png" alt="Icon" />
                <h2>Interior Detailing</h2>
                <p>
                  Deep cleaning of carpets, seats, along with dashboard and
                  console cleaning, leaving your car’s interior fresh and
                  spotless.
                </p>
              </div>
            </div>
            <div
              className="col-lg-3 col-md-6"
              data-aos="flip-left"
              data-aos-easing="ease-out-cubic"
              data-aos-duration={2000}
            >
              <div className="our-services-one">
                <img className="img-fluid" src="images/icon4.png" alt="Icon" />
                <h2>Specialty Services</h2>
                <p>
                  Ceramic coating, paint correction, headlight restoration, and
                  engine bay cleaning, all designed to enhance your vehicle’s
                  appearance and extend its longevity
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="why-chooseus-men">
        <div className="container chooseus-text">
          <div className="heading-men">
            <h2>Why Choose Us?</h2>
            {/* <p>Thousands of satisfied customers and top-rated car maintenance services.</p> */}
          </div>
          <div className="row mt-4 align-items-center">
            <div
              className="col-lg-6 col-md-6"
              data-aos="fade-down"
              data-aos-duration={2000}
            >
              <div className="why-chooseus-one-photo">
                <img
                  className="img-fluid"
                  src="images/why-choose-us-photo.png"
                  alt="Why Choose Us Photo"
                />
              </div>
            </div>
            <div
              className="col-lg-6 col-md-6"
              data-aos="zoom-in-up"
              data-aos-duration={2000}
            >
              <div className="row">
                <div className="col-lg-12 col-md-12">
                  <div className="why-chooseus-one">
                    <img
                      className="img-fluid"
                      src="images/whyicon1.svg"
                      alt="Why Choose Us Photo"
                    />
                    <h2>Expert 'Bros'</h2>
                    <p>
                      Our team is made up of true car enthusiasts—our ‘bros’—who
                      are not only skilled professionals but also deeply
                      passionate about cars. They’re trained in the latest
                      detailing techniques to ensure your vehicle gets the care
                      it deserves
                    </p>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="why-chooseus-one mt-4 mb-4">
                    <img
                      className="img-fluid"
                      src="images/whyicon2.svg"
                      alt="Why Choose Us Photo"
                    />
                    <h2>Premium Products</h2>
                    <p>
                      We use only premium, eco-friendly products for your
                      vehicle and the environment. Our products not only ensure
                      immediate satisfaction but also contribute to a healthier,
                      more sustainable future for both your ride and the planet.
                    </p>
                  </div>
                </div>
                <div className="col-lg-12 col-md-12">
                  <div className="why-chooseus-one">
                    <img
                      className="img-fluid"
                      src="images/whyicon3.svg"
                      alt="Why Choose Us Photo"
                    />
                    <h2>Convenience</h2>
                    <p>
                      With flexible scheduling and mobile services, we make it
                      easy for you to keep your car in pristine condition at
                      your convenient time and place.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="At-fingertips-men">
        <div className="container">
          <div className="row">
            <div
              className="col-lg-12 col-md-12"
              data-aos="zoom-in"
              data-aos-duration={2000}
            >
              <div className="At-fingertips-left">
                <div className="heading-men p-0">
                  <img
                    className="app-icon-photo"
                    src="images/app-icon.png"
                    alt="App Icon"
                  />
                  <h2>At your doorstep, By your fingertips</h2>
                  <p className="pt-2">
                    The most convenient, professional &amp; eco-friendly car
                    wash solution. <br /> At your doorstep, On tap of a button.
                  </p>
                  <div className="all-btn">
                    <div className="row mt-4 justify-content-center">
                      <div className="col-lg-2 play" >
                        <div className="btn1 btn2">
                          <a href="https://play.google.com/store/apps/details?id=com.washwoosh.userapp&pcampaignid=web_share">
                            <img
                              className="img-fluid"
                              src="images/d-app1.png"
                            />
                          </a>
                        </div>

                        <div className="btn1 btn2">
                        <a href="https://apps.apple.com/in/app/washwoosh-car-care-app/id6738276020">
                          <img className="img-fluid" src="images/d-app2.png" />
                        </a>
                      </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
